import React, { useState, useEffect, useCallback } from 'react';
import Modal from 'react-responsive-modal';
import CloseModal from '../svg/CloseModal';
import { I18n } from 'react-redux-i18n';
import CallApi from '../../../utils/CallApi';
import '../sass/customerAccount.css';
import CustomSwitch from '../../../components/Button/CustomSwitch';
import { Input, Button, Text } from '@capps/laddition-storybook';
import { Grid, Container } from '@material-ui/core';
import ImgInfo from '../img/ info.png';

function ModalSettings({ openModal, onClose, onSuccess, onError }) {
	const [loading, setLoading] = useState(false);
	const [switchStates, setSwitchStates] = useState({});
	const [accountData, setAccountData] = useState({
		bic: '',
		iban: '',
	});
	const [modifiedFields, setModifiedFields] = useState({});

	const settingsOptions = [
		{
			label: 'customerAccount.customerAccountManagement',
			dbKey: 'customer_account',
			type: 'switch',
		},
		{
			label: 'customerAccount.deactivationOnBalanceOverrun',
			dbKey: 'ca_auto_disable',
			type: 'switch',
		},
		{
			label: 'customerAccount.preventPaying',
			dbKey: 'ca_no_up_amount',
			type: 'switch',
		},
		{
			label: 'customerAccount.preventNegativeBalance',
			dbKey: 'ca_no_negative',
			type: 'switch',
		},
		{
			label: 'customerAccount.preventPaidTickets',
			dbKey: 'ca_no_us_ticket',
			type: 'switch',
		},
		{
			label: 'customerAccount.preventCountingCustomers',
			dbKey: 'ca_auto_disabled',
			type: 'switch',
		},
		{
			label: 'customerAccount.autoEmailNewTicket',
			dbKey: 'ca_auto_email_new_tk',
			type: 'switch',
		},
		{ label: 'customerAccount.customerBic', dbKey: 'bic', type: 'input' },
		{ label: 'customerAccount.customerIban', dbKey: 'iban', type: 'input' },
	];

	const getUserOptions = useCallback(async () => {
		try {
			const keys = settingsOptions.map(option => option.dbKey);
			const response = await CallApi('GET', 'customer-account/getOptions', '', {
				keys,
			});
			return response;
		} catch (error) {
			console.error('Error retrieving user options:', error);
		}
	}, []);

	const upsertUserOption = useCallback(async (key, value) => {
		try {
			await CallApi('PUT', 'customer-account/upsertOption', '', { key, value });
		} catch (error) {
			console.error('Error upserting user option:', error);
		}
	}, []);

	useEffect(() => {
		const loadUserOptions = async () => {
			setLoading(true);
			try {
				const response = await getUserOptions();
				if (response && response.length) {
					const data = response[0];
					const initialSwitchStates = settingsOptions.reduce((acc, option) => {
						acc[option.label] = data[option.dbKey] === '1';
						return acc;
					}, {});
					setSwitchStates(initialSwitchStates);
					setAccountData({
						bic: data.bic,
						iban: data.iban,
					});
				}
			} catch (error) {
				console.error('Error loading user options:', error);
			} finally {
				setLoading(false);
			}
		};

		if (openModal) {
			loadUserOptions();
		}
	}, [openModal, getUserOptions]);

	const handleChangeSwitch = (event, option) => {
		const isChecked = event.target.checked;
		setSwitchStates(prevState => ({
			...prevState,
			[option.label]: isChecked,
		}));

		setModifiedFields(prevModified => ({
			...prevModified,
			[option.dbKey]: isChecked ? '1' : '0',
		}));
	};

	const handleChangeInput = event => {
		const { name, value } = event.target;
		setAccountData(prevState => ({
			...prevState,
			[name]: value,
		}));

		setModifiedFields(prevModified => ({
			...prevModified,
			[name]: value,
		}));
	};

	const handleSubmit = async () => {
		try {
			setLoading(true);
			for (const [key, value] of Object.entries(modifiedFields)) {
				await upsertUserOption(key, value);
			}
			onSuccess();
			setModifiedFields({});
		} catch (error) {
			console.error('Erreur lors de la sauvegarde des paramètres:', error);
			onError();
		} finally {
			setLoading(false);
			onClose();
		}
	};

	return (
		<Modal
			classNames={{
				modal: 'export_history_modal',
				overlay: 'modal_export_history_overlay',
			}}
			open={openModal}
			showCloseIcon={false}
			closeOnOverlayClick={true}
			onClose={() => {
				if (loading) return false;
				onClose();
			}}>
			<div className="export_modal_content">
				<div
					className="close_icon"
					onClick={e => {
						if (loading) return false;
						onClose();
					}}>
					<CloseModal />
				</div>
				<h3>Paramètres</h3>
				<div className="settings_modal_main">
					<Container maxWidth="lg">
						<div>
							{settingsOptions.map(option => (
								<div className="modal-row p-2" key={option.label}>
									{option.type === 'switch' && (
										<>
											<p className="modal-text">{I18n.t(option.label)}</p>
											<CustomSwitch
												checked={switchStates[option.label] || false}
												onChange={e => handleChangeSwitch(e, option)}
												inputProps={{ 'aria-label': 'controlled' }}
											/>
										</>
									)}
								</div>
							))}
						</div>
						<div className="flex items-center mb-2">
							<img src={ImgInfo} className="img-info" alt="info" />
							<Text
								className="text-form-modal my-0"
								color="main"
								variant="body">
								{I18n.t('customerAccount.bicAndIbanInfo')}
							</Text>
						</div>
						<Grid container spacing={2}>
							<Grid item xs={12} sm={6}>
								<Input
									label={I18n.t('customerAccount.customerBic')}
									onChange={handleChangeInput}
									placeholder={I18n.t('customerAccount.customerBic')}
									value={accountData.bic}
									name="bic"
									isValid={accountData.bic ? true : false}
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
								<Input
									label={I18n.t('customerAccount.customerIban')}
									onChange={handleChangeInput}
									placeholder={I18n.t('customerAccount.customerIban')}
									value={accountData.iban}
									name="iban"
									isValid={accountData.iban ? true : false}
								/>
							</Grid>
						</Grid>
						<div className="btn-row">
							<Button
								color="secondary"
								label={I18n.t('accountMigration.btnValidate')}
								disabled={loading}
								onClick={handleSubmit}
							/>
						</div>
					</Container>
				</div>
			</div>
		</Modal>
	);
}

export default ModalSettings;
