import React from 'react';
import { Switch } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    switchBase: {
        color: '#bdbdbd',
        '&$checked': {
            color: '#5ac8f5',
        },
        '&$checked + $track': {
            backgroundColor: '#5ac8f5',
        },
        '&$checked:hover': {
        backgroundColor: 'rgba(90, 200, 245, 0.2)',
         },
    },
    checked: {},
    track: {
        backgroundColor: '#bdbdbd',
    },
});

const CustomSwitch = withStyles(styles)(({ classes, checked, onChange, ...props }) => (
    <Switch
        classes={{
            switchBase: classes.switchBase,
            checked: classes.checked,
            track: classes.track,
        }}
        checked={checked}
        onChange={onChange}
        inputProps={{ 'aria-label': 'controlled' }}
        {...props}
    />
));

export default CustomSwitch;