import axios from 'axios';
import config from './config';

/**
 * Call Api for GET, PUT, DELETE
 * @param {*} method
 * @param {*} route
 * @param {*} id
 * @param {*} body
 */
const CallApi = async (method, route, id, body, additionalHeader = {}) => {
	const finalResponse = [];

	try {
		const accessToken = window.localStorage.getItem('accessToken');
		let res = await axios({
			method: method,
			headers: {
				'content-type': 'application/json',
				Authorization: `accessToken ${accessToken}`,
				'id-company': sessionStorage.getItem('id-company'),
				...additionalHeader,
			},
			url: `${config.reportingUrl}api/${route}/${id}`,
			data: method !== 'GET' ? body : null,
			params: method === 'GET' ? body : null,
		});
		finalResponse.push(res.data);
	} catch (e) {
		throw e;
	}
	return finalResponse;
};

export default CallApi;
