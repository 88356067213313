import React, { useState, useEffect } from 'react';
import Modal from 'react-responsive-modal';
import CloseModal from '../svg/CloseModal';
import { I18n } from 'react-redux-i18n';
import CallApi from '../../../utils/CallApi';
import '../sass/customerAccount.css';
import { Input, Button, Text } from '@capps/laddition-storybook';
import { Grid, Container } from '@material-ui/core';
import CustomSwitch from '../../../components/Button/CustomSwitch';

function ModalAccountForm({ openModal, onClose, onSuccess, accountData }) {
	const [loading, setLoading] = useState(false);
	const [formData, setFormData] = useState({
		lastname: '',
		firstname: '',
		email: '',
		countryCode: '',
		phoneNumber: '',
		fullPhoneNumber: '',
		note: '',
		address: '',
		zipcode: '',
		city: '',
		isCompany: false,
		companyName: '',
		companyId: '',
		vatCode: '',
	});

	useEffect(() => {
		if (accountData) {
			const phoneNumberFromApi =
				accountData.customer_infos?.details_phone || '';
			const code = phoneNumberFromApi ? phoneNumberFromApi.slice(1, 3) : '';
			const number = phoneNumberFromApi ? phoneNumberFromApi.slice(3) : '';

			setFormData({
				lastname: accountData.lastname || '',
				firstname: accountData.firstname || '',
				email: accountData.customer_infos.details_email || '',
				countryCode: code || '',
				phoneNumber: number || '',
				note: accountData.customer_infos.note || '',
				address: accountData.customer_infos.address_street || '',
				zipcode: accountData.customer_infos.address_zipcode || '',
				city: accountData.customer_infos.address_city || '',
				isCompany: accountData.customer_infos.is_company || 0,
				companyName: accountData.customer_infos.customer_company_name || '',
				companyId: accountData.customer_infos.customer_company_id || '',
				vatCode: accountData.customer_infos.customer_company_vat || '',
			});
		}
	}, [accountData]);

	useEffect(() => {
		setFormData(prevFormData => ({
			...prevFormData,
			fullPhoneNumber: `+${prevFormData.countryCode}${prevFormData.phoneNumber}`,
		}));
	}, [formData.countryCode, formData.phoneNumber]);

	const handleChange = event => {
		const { name, value } = event.target;
		setFormData(prevState => ({
			...prevState,
			[name]: value,
		}));
	};

	const handleSwitchChange = async event => {
		const isChecked = event.target.checked ? 1 : 0;
		setFormData(prevState => ({
			...prevState,
			isCompany: isChecked,
		}));
	};

	const handleSubmit = async event => {
		event.preventDefault();
		const customerId = accountData.id;
		try {
			await CallApi('PUT', 'customer-account/customer/updateData', '', {
				customerId,
				formData,
			});
		} catch (error) {
			console.log(error);
		}
	};

	return (
		<Modal
			classNames={{
				modal: 'export_history_modal',
				overlay: 'modal_export_history_overlay',
			}}
			open={openModal}
			showCloseIcon={false}
			closeOnOverlayClick={true}
			onClose={() => {
				if (loading) return false;
				onClose();
			}}>
			<div className="export_modal_content">
				<div
					className="close_icon"
					onClick={e => {
						if (loading) return false;
						onClose();
					}}>
					<CloseModal />
				</div>
				<h3>Informations du client</h3>
				<div className="settings_modal_main">
					<Container maxWidth="lg">
						<form onSubmit={handleSubmit}>
							<Text className="text-form-modal" color="main" variant="h4">
								Identité et contact
							</Text>

							<Grid container spacing={2}>
								<Grid item xs={12} sm={6}>
									<Input
										label={I18n.t('customerAccount.name')}
										onChange={handleChange}
										placeholder={I18n.t('customerAccount.name')}
										value={formData.lastname}
										name="lastname"
										isValid={formData.lastname ? true : false}
									/>
								</Grid>
								<Grid item xs={12} sm={6}>
									<Input
										label={I18n.t('customerAccount.firstname')}
										onChange={handleChange}
										placeholder={I18n.t('customerAccount.firstname')}
										value={formData.firstname}
										name="firstname"
										isValid={formData.firstname ? true : false}
									/>
								</Grid>
							</Grid>
							<Grid container spacing={2}>
								<Grid item xs={12} sm={8}>
									<Input
										label={I18n.t('customerAccount.email')}
										onChange={handleChange}
										placeholder={I18n.t('customerAccount.email')}
										value={formData.email}
										name="email"
										isValid={formData.email ? true : false}
									/>
								</Grid>
								<Grid item xs={12} sm={1}>
									<Input
										label={I18n.t('customerAccount.countryCode')}
										onChange={handleChange}
										placeholder={I18n.t('customerAccount.countryCode')}
										value={formData.countryCode}
										name="countryCode"
										isValid={formData.countryCode ? true : false}
									/>
								</Grid>
								<Grid item xs={12} sm={3}>
									<Input
										label={I18n.t('customerAccount.phoneNumber')}
										onChange={handleChange}
										placeholder={I18n.t('customerAccount.phoneNumber')}
										value={formData.phoneNumber}
										name="phoneNumber"
										isValid={formData.phoneNumber ? true : false}
									/>
								</Grid>
							</Grid>
							<Text
								className="text-form-modal"
								color="main"
								variant="h4"
								style={{ marginTop: '20px' }}>
								Adresse
							</Text>
							<Grid container spacing={2}>
								<Grid item xs={12} sm={12}>
									<Input
										label={I18n.t('customerAccount.address')}
										onChange={handleChange}
										placeholder={I18n.t('customerAccount.address')}
										value={formData.address}
										name="address"
										isValid={formData.address ? true : false}
									/>
								</Grid>
							</Grid>
							<Grid container spacing={2}>
								<Grid item xs={12} sm={3}>
									<Input
										label={I18n.t('customerAccount.zipCode')}
										onChange={handleChange}
										placeholder={I18n.t('customerAccount.zipCode')}
										value={formData.zipcode}
										name="zipcode"
										isValid={formData.zipcode ? true : false}
									/>
								</Grid>
								<Grid item xs={12} sm={3}>
									<Input
										label={I18n.t('customerAccount.city')}
										onChange={handleChange}
										placeholder={I18n.t('customerAccount.city')}
										value={formData.city}
										name="city"
										isValid={formData.city ? true : false}
									/>
								</Grid>
							</Grid>
							<Text
								className="text-form-modal"
								color="main"
								variant="h4"
								style={{ marginTop: '20px' }}>
								Informations complémentaires
							</Text>
							<Grid container spacing={2}>
								<Grid item xs={12} sm={12}>
									<Input
										label={I18n.t('customerAccount.additionalInformation')}
										onChange={handleChange}
										placeholder={I18n.t(
											'customerAccount.additionalInformation'
										)}
										value={formData.note}
										name="note"
										isValid={formData.note ? true : false}
									/>
								</Grid>
							</Grid>
							<Text
								className="text-form-modal"
								color="main"
								variant="h4"
								style={{ marginTop: '20px' }}>
								Société
							</Text>
							<Grid container spacing={2} alignItems="center">
								<Grid item xs={12} sm={2}>
									<CustomSwitch
										checked={!!formData.isCompany}
										onChange={handleSwitchChange}
										name="isCompany"
										inputProps={{ 'aria-label': 'controlled' }}
									/>
								</Grid>
								<Grid item xs={12} sm={4}>
									<Input
										label={I18n.t('customerAccount.companyName')}
										onChange={handleChange}
										placeholder={I18n.t('customerAccount.companyName')}
										value={formData.companyName}
										name="companyName"
										disabled={formData.isCompany ? false : true}
										isValid={
											formData.companyName && formData.isCompany ? true : false
										}
									/>
								</Grid>
								<Grid item xs={12} sm={3}>
									<Input
										label={I18n.t('customerAccount.siren')}
										onChange={handleChange}
										placeholder={I18n.t('customerAccount.siren')}
										value={formData.companyId}
										name="companyId"
										disabled={formData.isCompany ? false : true}
										isValid={
											formData.companyId && formData.isCompany ? true : false
										}
									/>
								</Grid>
								<Grid item xs={12} sm={3}>
									<Input
										label={I18n.t('customerAccount.tvaNumber')}
										onChange={handleChange}
										placeholder={I18n.t('customerAccount.tvaNumber')}
										value={formData.vatCode}
										name="vatCode"
										disabled={formData.isCompany ? false : true}
										isValid={
											formData.vatCode && formData.isCompany ? true : false
										}
									/>
								</Grid>
							</Grid>
							<div className="btn-row">
								<Button
									color="secondary"
									label={I18n.t('accountMigration.btnValidate')}
									onClick={onSuccess}
								/>
							</div>
						</form>
					</Container>
				</div>
			</div>
		</Modal>
	);
}

export default ModalAccountForm;
