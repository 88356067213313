import React, { useState, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Divider from '@material-ui/core/Divider';
import AccordionActions from '@material-ui/core/AccordionActions';
import IconCheckbox from '../IconCheckbox';
import Filters from '../Filters/Filters';
import Alert from '@material-ui/lab/Alert';
import { Paper } from '@material-ui/core';

export default function AccountsTable({
	type,
	accounts,
	vats,
	productTypes,
	paymentTypes,
	links,
	products,
	floors,
	onLinkChange,
	onDelete,
}) {
	const [currentAccountId, setCurrentAccountId] = useState(null);

	let data = [];
	let headers = [];
	let _accounts = accounts[type] || [];

	switch (type) {
		case 'payment':
			data = paymentTypes;
			headers.push('Moyen de paiement', 'Compte associé');
			break;
		case 'vat':
			data = vats;
			headers.push('Tax rate', 'Compte associé');
			break;
		case 'product':
			data = productTypes;
			headers.push('Type de produit', 'Compte associé', 'Taux TVA');
			break;
	}

	const onSelectAll = accountId => {
		const keyIdTable = type == 'vat' ? 'tax_rate' : 'id';
		let toInsert = [];
		for (let d of data) {
			if (
				!links[accountId] ||
				(links[accountId] && !links[accountId][d[keyIdTable]])
			) {
				toInsert.push(d[keyIdTable]);
			}
			onLinkChange(accountId, toInsert, type, true, []);
		}
	};
	return (
		<>
			{_accounts.map(a => {
				let labelFloors = '';
				if (type == 'product')
					if (a.all_floors) labelFloors = 'Toutes les salles';
					else
						labelFloors = a.floors
							.map(id_floor => floors.find(f => f.id == id_floor).name)
							.join(', ');

				if (['achats', 'ventes', 'customer'].includes(type)) {
					const link = a.linking[0];
					if (link) {
						return (
							<Paper style={{ padding: 15 }}>
								<Typography>
									{`${a.label} (${a.account_number}) - identifiant : ${link.id_table}`}{' '}
								</Typography>
								<Button
									size="small"
									color="primary"
									onClick={() => onDelete(a._id)}>
									Supprimer compte
								</Button>
							</Paper>
						);
					} else {
						return '';
					}
				} else {
					const allChecked =
						links[a._id] && Object.keys(links[a._id]).length == data.length;

					return (
						<Accordion
							expanded={currentAccountId == a.id}
							onChange={() =>
								setCurrentAccountId(currentAccountId == a.id ? null : a.id)
							}>
							<AccordionSummary
								expandIcon={<ExpandMoreIcon />}
								id="panel1-header">
								<Typography>{`${a.label} (${a.account_number}) - ${labelFloors}`}</Typography>
							</AccordionSummary>
							<AccordionDetails>
								<div className="account-table-container">
									<div
										className="account-table-row"
										key={'000'}
										style={{ display: 'flex' }}>
										<div className="account-table-row-name">
											<ListItemIcon
												onClick={_ => {
													onSelectAll(a._id);
												}}>
												<IconCheckbox checked={allChecked} />
											</ListItemIcon>
											<p> Tout sélectionner </p>
										</div>
									</div>
									<hr />

									{data.map(row => {
										const keyIdTable = type == 'vat' ? 'tax_rate' : 'id';
										const link =
											links[a._id] && links[a._id][row[keyIdTable]]
												? links[a._id][row[keyIdTable]]
												: null;
										let selectedTaxRates = [];
										if (link && link.all_tax_rates) {
											selectedTaxRates = [...vats];
										} else if (link && link.tax_rates) {
											selectedTaxRates = vats.filter(
												v => link.tax_rates.find(tr => tr == v.tax_rate) != null
											);
										}

										return (
											<div
												className="account-table-row"
												key={row.id}
												style={{ display: 'flex' }}>
												<div className="account-table-row-name">
													<ListItemIcon
														onClick={_ =>
															onLinkChange(
																a._id,
																row[keyIdTable],
																type,
																!!link ? false : true,
																[]
															)
														}>
														<IconCheckbox checked={!!link} />
													</ListItemIcon>
													<p>{row.name} </p>
												</div>

												{type == 'product' && link ? (
													<div className="account-table-row-vat">
														<Filters
															multiple={true}
															monoSelect={false}
															disabledClickAway={true}
															label="Toutes les TVA"
															datas={vats}
															paramsLabel="name"
															paramsValue="tax_rate"
															selected={selectedTaxRates}
															inputClassName="dataExport_filter_input"
															inputLabelClassName="dataExport_input_label"
															onClose={(data, pValue) => {
																if (!!data) {
																	onLinkChange(
																		a._id,
																		row.id.toString(),
																		type,
																		false,
																		data.map(t => t.tax_rate)
																	);
																}

																return;
															}}
														/>
													</div>
												) : null}
											</div>
										);
									})}
								</div>
							</AccordionDetails>
							<Divider />
							<AccordionActions>
								<Button
									size="small"
									color="primary"
									onClick={() => onDelete(a._id)}>
									Supprimer compte
								</Button>
							</AccordionActions>
						</Accordion>
					);
				}
			})}

			{!_accounts.length ? (
				<div style={{ marginTop: 20 }}>
					<Alert severity="info">
						Aucun compte comptable de ce type n'est configuré
					</Alert>
				</div>
			) : null}
		</>
	);
}
