import React, { useState, useEffect } from 'react';
import { I18n } from 'react-redux-i18n';
import FiltersVat from '../components/Filters/FiltersVat';
import ReactTable from 'react-table';
import EditableCell from './EditableCell';
import CellEditable from './CellEditable';
import { connect } from 'react-redux';
import { vatExportLabels } from '../constant';
import CallApi from '../../../utils/CallApi';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ToggleButton from '@material-ui/lab/ToggleButton';
import { makeStyles } from '@material-ui/core';

import { addFlashMessage } from '../../../common/flashMessage/actions/flashMessages';
import CloseModal from '../../CustomerAccount/svg/CloseModal';
import AdminAccounts from './AdminAccounts';
import GlobalSettings from '../components/GlobalSettings';
import AccountsHandler from '../components/AccountsHandler';
import AccountsTable from '../components/AccountTable';

const accountTypes = [
	{ label: 'Paiements', type: 'payment' },
	{ label: 'TVA', type: 'vat' },
	{ label: 'Produits', type: 'product' },
	{ label: 'Compte client', type: 'customer' },
	{ label: 'Achats', type: 'achats' },
	{ label: 'Ventes hors caisse', type: 'ventes' },
];

const useStyles = makeStyles(theme => ({
	root: {
		margin: theme.spacing(0.1),
		backgroundColor: '#e7e7e8',
		fontFamily: 'FuturaBook',
		border: 'none',
	},
	togglBtn: {
		margin: '2px 2px 2px 2px!important',
		textTransform: 'none',
		fontFamily: 'FuturaBook',
		color: 'none',
		fontSize: '13px',
		border: 'none',
		width: '110px',
		height: '30px',
		'&$selected': {
			borderRadius: '3px',
			color: '#262967',
			backgroundColor: 'white',
			margin: '2px 4px 2px 4px',
			'&:hover': {
				backgroundColor: 'white',
			},
		},
		'&:hover': {
			backgroundColor: 'transparent',
		},
		'&:first-child ': {
			width: '135px',
		},
		'&:last-child ': {
			width: '170px',
		},
	},
	selected: { borderRadius: '3px', height: '30px', width: '110px' },
}));

const paramsLabel = {
	society: 'Société',
	etab: 'Etab',
	journal: 'Journal',
	pieceType: 'Type de pièce',
	type: 'Type',
	tiers: 'Tiers',
	//axeAnal: 'Axe analytique',
	sectionAnal: 'Section analytique',
	devise: 'Devise',
	lib: 'Libellé',
	//paymentMod: 'Mode de paiement',
	//reglMode: 'Mode Rég.',
	//profilVat: 'Profil TVA',
	//intraco: 'Intraco',
	//nature: 'Nature',
	//sectionAxe1: 'Axe 1',
	//sectionAxe2: 'Axe 2',
	//sectionAxe3: 'Axe 3',
};

const getEdidtableCells = (r, accSelected, onChangeFct) => {
	const accInfos = r.original;
	const isDefault = r.column.id === 'Default' ? true : false;
	const isLabel = r.column.id === 'label';
	const cellVal = isLabel
		? r.original.label
		: r.column.id !== r.original.indicator
		? ''
		: r.original.account_number;
	const valueToUpdate = isLabel ? 'label' : 'account_number';
	return (
		<CellEditable
			val={cellVal}
			onBlurFct={(val, isLabel) => {
				accInfos[valueToUpdate] = isLabel ? val : parseInt(val);
				onChangeFct(accInfos, isLabel);
			}}
			isLabel={isLabel}
		/>
	);
};

function NewVatSettings({ onCloseDrawer, addFlashMessage }) {
	const [accountVatList, setAccountVatList] = useState(null);
	const [initFilterValue, setInitFilterValue] = useState(
		JSON.parse(JSON.stringify(accountTypes[0]))
	);
	const [allLoaded, setAllLoaded] = useState(false);
	const [valChanged, setValChanged] = useState(false);
	const [toggleSelected, setToggleSelected] = useState('accounts');
	const [floors, setFloors] = useState(null);
	const [vats, setVats] = useState(null);
	const [productTypes, setProductTypes] = useState(null);
	const [paymentTypes, setPaymentTypes] = useState(null);
	const [products, setProducts] = useState(null);
	const [accountLinking, setAccountLinking] = useState(null);
	const [contacts, setContacts] = useState(null);

	const classes = useStyles();

	useEffect(() => {
		getVatListAccounts();
		getAllAccountLinking();
		getAllFloors();
		getAllVat();
		getAllProductTypes();
		getAllPaymentTypes();
		getAllProducts();
		getContacts();
	}, []);

	useEffect(() => {
		if (
			accountVatList &&
			floors &&
			vats &&
			productTypes &&
			paymentTypes &&
			accountLinking &&
			products
		)
			setAllLoaded(true);
	}, [
		paymentTypes,
		vats,
		productTypes,
		accountVatList,
		initFilterValue,
		products,
	]);

	const fetcher = async (route, setter) => {
		try {
			const res = await CallApi('GET', route, '', '');

			if (res && res[0] && res[0].datas && setter) {
				return setter(res[0].datas);
			}
		} catch (error) {
			addFlashMessage({
				type: 'danger',
				text: 'Une erreur est survenue',
				persistent: false,
			});
		}
	};

	const getAllFloors = async () => {
		await fetcher('user/floorsName', setFloors);
	};
	const getAllVat = async () => {
		await fetcher('user/vat', setVats);
	};
	const getAllProductTypes = async () => {
		await fetcher('user/productTypes', setProductTypes);
	};
	const getAllPaymentTypes = async () => {
		await fetcher('user/paymentTypes', setPaymentTypes);
	};
	const getAllProducts = async () => {
		await fetcher('user/products', setProducts);
	};
	const getAllAccountLinking = async () => {
		await fetcher('exportVat/getAccountLinking', setAccountLinking);
	};
	const getVatListAccounts = async () => {
		await fetcher('exportVat/getVatAccounts', setAccountVatList);
	};
	const getContacts = async () => {
		await fetcher('exportVat/getContacts', setContacts);
	};

	const updateSettings = async () => {
		// format object in array
		const _accountLinking = [];
		for (let account_id in accountLinking) {
			for (let id_table in accountLinking[account_id]) {
				if (accountLinking[account_id][id_table].tax_rates.length) {
					_accountLinking.push(
						...accountLinking[account_id][id_table].tax_rates.map(tr => ({
							...accountLinking[account_id][id_table],
							tax_rate: tr,
						}))
					);
				} else {
					_accountLinking.push({
						...accountLinking[account_id][id_table],
						tax_rate: null,
					});
				}
			}
		}

		try {
			await CallApi('POST', 'exportVat/updateVatLinking', '', {
				accountLinking: _accountLinking,
			})
				.then(results => {
					if (results && results.length) {
						addFlashMessage({
							type: 'success',
							text: 'Vos comptes comptables ont été mis à jour',
							persistent: false,
						});
						setAccountLinking(results[0].result);
						setValChanged(false);
					}
				})
				.catch(err => {
					console.error(err);
					addFlashMessage({
						type: 'danger',
						text: 'Une erreur est survenue',
						persistent: false,
					});
					return err;
				});
		} catch (err) {
			console.log('err');
		}
	};

	const updateVatKeys = async vatKeys => {
		try {
			const changes = Object.keys(vatKeys).reduce((acc, key) => {
				if (vatKeys[key] != accountVatList['vatKeys'][key])
					acc[key] = vatKeys[key];
				return acc;
			}, {});

			if (Object.values(changes).length) setValChanged(true);

			if (changes.hasOwnProperty('account_vat_ecriturenum')) {
				await CallApi('PATCH', 'exportVat/ecritureNum', '', {
					value: changes['account_vat_ecriturenum'],
				})
					.then(results => {
						//
					})
					.catch(err => {
						console.error(err);
						return err;
					});
			}

			await CallApi('POST', 'exportVat/updateVatKeys', '', {
				vatKeys: changes,
			})
				.then(results => {
					if (results && results.length) {
						addFlashMessage({
							type: 'success',
							text: 'Vos paramètres ont été mis à jour',
							persistent: false,
						});
						setAccountVatList({
							...accountVatList,
							vatKeys: results[0].result,
						});
					}
				})
				.catch(err => {
					console.error(err);
					addFlashMessage({
						type: 'danger',
						text: 'Une erreur est survenue',
						persistent: false,
					});
					return err;
				});
		} catch (err) {
			console.log(err);
		}
	};

	const onCellDataChanged = (
		accountId, // corresponds a _id
		tableId,
		type,
		create = true,
		linkVats = [] // pour les product types
	) => {
		let _accountLinking = JSON.parse(JSON.stringify(accountLinking));
		let tableIds = Array.isArray(tableId) ? tableId : [tableId];

		if (!_accountLinking[accountId]) _accountLinking[accountId] = {};

		for (let _tableId of tableIds) {
			if (type == 'product') {
				delete _accountLinking[accountId][_tableId];

				const all_tax_rates = create || vats.length == linkVats.length;
				if (create || linkVats.length)
					_accountLinking[accountId][_tableId] = {
						id: '',
						id_account: accountId,
						id_table: _tableId,
						type,
						tax_rates: create || all_tax_rates ? [] : [...linkVats],
						all_tax_rates,
					};
			} else {
				if (create) {
					_accountLinking[accountId][_tableId] = {
						id: '',
						id_account: accountId,
						id_table: _tableId,
						type,
						tax_rates: [],
						all_tax_rates: null,
					};
				} else {
					delete _accountLinking[accountId][_tableId];
				}
			}
		}

		setAccountLinking(_accountLinking);
		setValChanged(true);
	};

	const handleDeleteAcc = async id => {
		try {
			const res = await CallApi('POST', 'exportVat/deleteVatAccounts', '', {
				id,
			})
				.then(results => {
					refreshAccounts();
					addFlashMessage({
						type: 'success',
						text: 'Votre compte comptables a bien été supprimé',
						persistent: false,
					});
				})
				.catch(err => {
					console.log(err);
					addFlashMessage({
						type: 'danger',
						text: 'Une erreur est survenue',
						persistent: false,
					});
				});
		} catch (error) {
			console.log(error);
		}
	};

	const deleteAccount = async id => {
		if (
			window.confirm('Êtes-vous sûr de voiloir supprimer ce compte comptable ?')
		) {
			await handleDeleteAcc(id);
		}
	};

	const handleCloseDrawer = e => {
		onCloseDrawer(valChanged);
		setValChanged(false);
	};

	const handleToggleMenu = (e, newAlignement) => {
		if (newAlignement) setToggleSelected(newAlignement);
	};

	const refreshAccounts = async () => {
		getVatListAccounts();
		getAllAccountLinking();
	};

	const eventCreateAccount = async type => {
		await refreshAccounts();
		handleToggleMenu(null, 'accounts');
		const filter = accountTypes.find(at => at.type == type);
		setInitFilterValue(filter);
	};

	return (
		<div className="vat-settings-container">
			<div className="vat-settings-header">
				<div className="vat-settings-title">
					<h4>Configuration des comptes</h4>
					<div
						onClick={e => {
							handleCloseDrawer(e);
						}}>
						<CloseModal />
					</div>
				</div>
				<div className="vat-setting-toggle">
					<ToggleButtonGroup
						classes={{ root: classes.root }}
						value={toggleSelected}
						exclusive
						onChange={handleToggleMenu}
						aria-label="selected toggle">
						<ToggleButton
							classes={{ root: classes.togglBtn, selected: classes.selected }}
							value="accounts">
							<span>Comptes</span>
						</ToggleButton>
						<ToggleButton
							classes={{ root: classes.togglBtn, selected: classes.selected }}
							value="handler">
							<span>Créer compte</span>
						</ToggleButton>
						<ToggleButton
							classes={{ root: classes.togglBtn, selected: classes.selected }}
							value="configuration">
							<span>Configuration</span>
						</ToggleButton>
					</ToggleButtonGroup>
				</div>
			</div>

			<React.Fragment>
				{toggleSelected === 'accounts' ? (
					<div className="vat-settings-main">
						<div className="vat-settings-table-container">
							{allLoaded ? (
								<>
									<div className="vat-settings-switch">
										<div className="vat-settings-filter-container">
											<span className="export-filter-title">
												{I18n.t('exportReport.accounts')}
											</span>

											<div
												className="vat-filter-list"
												style={{ marginBottom: 20, marginTop: 10 }}>
												<FiltersVat
													multiple={false}
													monoSelect={true}
													label={initFilterValue.label}
													selected={[initFilterValue]}
													datas={JSON.parse(JSON.stringify(accountTypes))}
													paramsLabel="label"
													paramsValue="type"
													inputClassName="dataExport_filter_input"
													inputLabelClassName="dataExport_input_label"
													onClose={(data, pValue) => {
														if (!!data) {
															setInitFilterValue(data);
														}
														return;
													}}
												/>

												{/*	<button className="vat-btn-add-account">Ajouter compte</button>*/}
											</div>
										</div>
									</div>
									<AccountsTable
										type={initFilterValue.type}
										accounts={accountVatList}
										floors={floors}
										vats={vats}
										productTypes={productTypes}
										paymentTypes={paymentTypes}
										links={accountLinking}
										products={products}
										onLinkChange={onCellDataChanged}
										onDelete={deleteAccount}
									/>
									<div className="vat-settings-footer">
										<button
											className={`btn-vat-settings validation ${
												valChanged ? '' : ' disabled'
											}`}
											disabled={!valChanged}
											onClick={() => {
												//if (canValidate) {
												updateSettings();
												//}
											}}>
											Valider
										</button>
									</div>
								</>
							) : (
								'Chargement des données...'
							)}
						</div>
					</div>
				) : toggleSelected === 'handler' ? (
					<AccountsHandler
						floors={floors}
						contacts={contacts}
						onCreate={eventCreateAccount}
						accounts={accountVatList}
					/>
				) : (
					<div className="vat-account-admin">
						<AdminAccounts
							vatKeys={accountVatList['vatKeys']}
							onSave={updateVatKeys}
						/>
					</div>
				)}
			</React.Fragment>
		</div>
	);
}

function mapStateToProps(state) {
	return {};
}

export default connect(mapStateToProps, { addFlashMessage })(NewVatSettings);
