import React, { useState, useEffect } from 'react';
import { I18n } from 'react-redux-i18n';
import Filters from '../components/Filters/Filters';
import ImgCalendar from '../components/Filters/calendar.svg';
import moment from 'moment';
import FiltersVat from '../components/Filters/FiltersVat';
const exportTypes = [
	{ label: 'Lignes de ventes', route: 'dataExport/getOrderLines' },
	// {
	// 	label: 'Lignes de ventes à emporter',
	// 	route: 'dataExport/getDeliveryOrderLines',
	// },
];

const orderTypes = [
	{ label: 'Sur place', type: 1 },
	{
		label: 'À emporter',
		type: 2,
	},
	{
		label: 'Livraison',
		type: 3,
	},
];

function HeaderExport({
	companies,
	onTakeAwaySelect,
	orderTypes,
	onDatePickerClick,
	selectedCompanies,
	onCompaniesChange,
	onRouteChanges,
	dateBegin,
	dateBeginLabel,
	dateEnd,
	dateEndLabel,
}) {
	return (
		<div className="export-filters">
			<div className="export-filters-main">
				<div className="export-filter-container">
					<span className="export-filter-title">
						{I18n.t('globalLabels.date')}
					</span>
					<div
						className="datepicker-input"
						onClick={() => {
							onDatePickerClick();
						}}>
						<div className="datepicker-label">
							<span>{`${dateBegin ? dateBeginLabel : '-- -- --'} / ${
								dateEnd ? dateEndLabel : '-- -- --'
							}`}</span>
						</div>
						<img src={ImgCalendar} />
					</div>
				</div>
				<div className="export-filter-container">
					<span className="export-filter-title">
						{I18n.t('globalLabels.companies')}
					</span>
					{companies && companies.length == 1 ? (
						<p style={{ marginTop: 20 }}>{companies[0].company_name}</p>
					) : (
						<FiltersVat
							multiple={false}
							monoSelect={true}
							label={
								selectedCompanies[0]
									? companies.find(c => c.id_company == selectedCompanies[0])
											.company_name
									: ''
							}
							datas={companies}
							//selected={selectedCompanies}
							paramsLabel="company_name"
							paramsValue="id_company"
							inputClassName="dataExport_filter_input"
							inputLabelClassName="dataExport_input_label"
							onClose={data => {
								if (data && data.id_company) {
									onCompaniesChange([data.id_company]);
								}
							}}
						/>
					)}
				</div>
			</div>
		</div>
	);
}

export default HeaderExport;
