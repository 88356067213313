import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { Router, browserHistory, Route } from 'react-router';
import AppFront from './front/AppFront';
import NotFound from './front/NotFound';
import Arcplan from './components/Arcplan';
import Arcplan2 from './components/Arcplan2';
import ConnectedAs from './components/ConnectedAs';
import LinkPartners from './module/Partnership/LinkPartners';
import Partner from './module/Partner/Partner';
import Documentation from './module/Documentation/containers/Documentation';
import OuiLink from './module/OuiLink/containers/OuiLink';
import GenerateFiskTokenPage from './module/Fisc/containers/GenerateFiscTokenPage';
import FiscAccountAccessPage from './module/Fisc/containers/FiscAccountAccessPage';
import DetailsCloture from './module/Fisc/containers/DetailsCloture';
import DetailsService from './module/Fisc/containers/DetailsService';
import DetailsCommande from './module/Fisc/containers/DetailsCommande';
import ErrorsPage from './module/Fisc/containers/ErrorsPage';
import FiscPage from './module/Fisc/containers/FiscPage';
import requireAuth from './utils/requireAuth';
import Migration from './module/AccountMigration/Migration';
import Video from './module/Video/containers/Video';
import TechnicalIntervention from './module/Fisc/containers/TechnicalIntervention';
import EmailValidation from './module/AccountMigration/EmailValidation';
import MigrationStep1 from './module/AccountMigration/MigrationStep1';
import DetailsMvtCaisse from './module/Fisc/containers/DetailsMvtCaisse';
import DetailsOrderLines from './module/Fisc/containers/DetailsOrderLines';
import DetailsReceipt from './module/Fisc/containers/DetailsReceipt';
import DetailsPaymentLines from './module/Fisc/containers/DetailsPaymentLines';
import DetailsCredit from './module/Fisc/containers/DetailsCredit';
import DetailsError from './module/Fisc/containers/DetailsError';
import FAQ from './module/FAQ/containers/FAQ';
import CustomerAccount from './module/CustomerAccount/containers/CustomerAccount';
import CustomerAccountDetails from './module/CustomerAccount/containers/CustomerAccountDetails';
import DocSlite from './components/DocSlite';
import BarDistrib from './module/BarDistrib/containers/BarDistrib';
import DataExport from './module/DataExport/containers/DataExport';
import FeedContainer from './common/Feed/FeedContainer';
import Maintenance from './components/Maintenance/Maintenance';
import ExportVat from './module/ExportVat/containers/ExportVat';
import SuiteVideos from './module/SuiteVideos/containers/SuiteVideos';
import ControlStrip from './module/ControlStrip/containers/ControlStrip';
import ShiftDetails from './module/ShiftDetails/containers/ShiftDetails';
import { UnauthorizedServicePage } from '@capps/subuser-tools';
// import ReportContainer from './module/Reports/containers/ReportsContainer';
// import BookingsReport from './module/Reports/containers/BookingsReport';

const Root = ({ store, logPageView }) => (
	<Provider store={store}>
		<Router history={browserHistory} onUpdate={logPageView}>
			<Route
				path="/"
				component={AppFront}
				onEnter={(nextState, replace, next) => {
					requireAuth(store, nextState, replace, next);
				}}
				onChange={(prevState, nextState, replace, next) => {
					requireAuth(store, nextState, replace, next);
				}}>
				<Route path="reporting/:reportName" component={Arcplan} />
				<Route path="reporting-sales/:reportName" component={Arcplan2} />
				<Route path="videos" component={Video} />
				<Route path="videos-suite" component={SuiteVideos} />
				<Route path="connect-as/:viewAsCustomerToken" component={ConnectedAs} />
				<Route
					path="FAQ"
					component={FAQ}
					onEnter={(nextState, replace, next) => {
						requireAuth(store, nextState, replace, next);
					}}
					onChange={(prevState, nextState, replace, next) => {
						requireAuth(store, nextState, replace, next);
					}}
				/>
				<Route path="documentation" component={Documentation} />
				<Route path="ouilink" component={OuiLink} />
				<Route path="partenaires" component={Partner} />
				<Route path="doc" component={DocSlite} />
				<Route
					path="generate-connexion-token"
					component={GenerateFiskTokenPage}
				/>
				<Route path="fisc-connect-as" component={FiscAccountAccessPage} />
				<Route exact path="administration-fiscale" component={FiscPage} />
				<Route path="administration-fiscale/errors" component={ErrorsPage} />
				<Route
					path="administration-fiscale/errors/details/:id"
					component={DetailsError}
				/>
				<Route
					path="administration-fiscale/clotures/details/:id"
					component={DetailsCloture}
				/>
				<Route
					path="administration-fiscale/services/details/:id"
					component={DetailsService}
				/>
				<Route
					path="administration-fiscale/commandes/details/:id"
					component={DetailsCommande}
				/>
				<Route
					path="administration-fiscale/mvtcaisse/details/:id"
					component={DetailsMvtCaisse}
				/>
				<Route
					path="administration-fiscale/orderlines/details/:id"
					component={DetailsOrderLines}
				/>
				<Route
					path="administration-fiscale/receipt/details/:id"
					component={DetailsReceipt}
				/>
				<Route
					path="administration-fiscale/paymentlines/details/:id"
					component={DetailsPaymentLines}
				/>
				<Route
					path="administration-fiscale/credit/details/:id"
					component={DetailsCredit}
				/>
				<Route
					path="administration-fiscale/technical-intervention"
					component={TechnicalIntervention}
				/>
				<Route path="partnership" component={LinkPartners} />
				<Route
					path="account/migration"
					component={Migration}
					onEnter={(nextState, replace, next) => {
						requireAuth(store, nextState, replace, next);
					}}
					onChange={(prevState, nextState, replace, next) => {
						requireAuth(store, nextState, replace, next);
					}}
				/>{' '}
				{/*<Route path={'maintenance-info'} component={FeedContainer} />*/}
				<Route path={'feed/:name'} component={FeedContainer} />
				<Route path={'account/migration/step-1'} component={MigrationStep1} />
				<Route path="check-new-email" component={EmailValidation} />
				<Route path="customer-account" component={CustomerAccount} />
				<Route path="customer-account/:id" component={CustomerAccountDetails} />
				<Route path="warelist" component={BarDistrib} />
				<Route path="export-data" component={DataExport} />
				<Route path="export-vat" component={ExportVat} />
				<Route path="unauthorized" component={UnauthorizedServicePage} />
				{/*<Route*/}
				{/*path='report/'*/}
				{/*component={ReportContainer}*/}
				{/*>*/}
				{/*<Route*/}
				{/*path='booking'*/}
				{/*component={BookingsReport}*/}
				{/*/>*/}
				{/*</Route>*/}
				<Route path="reporting-v2/control-strip" component={ControlStrip} />
				<Route path="reporting-v2/shift-details" component={ShiftDetails} />
				<Route path="*" component={NotFound} />
			</Route>
		</Router>
	</Provider>
);

Root.propTypes = {
	store: PropTypes.object.isRequired,
};

export default Root;
