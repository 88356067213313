import React, { Component } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import getPaymentName from '../../../utils/getPaymentName';
import priceFormatting from '../../../utils/priceFormatting';
import { I18n } from 'react-redux-i18n';

const WAIT_INTERVAL = 2000;
let tmpCount = 0;
class CustomerNoteList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			filter: props.filter,
			dataNotes: props.dataNotes,
			paymentIds: [],
			checkboxAll: false,
			selectedCheckboxes: {},
			selectedRadio: '',
			creditDetailTs: '',
			creditDetail: '',
			paymentTypes: props.paymentTypes,
			language: props.language,
			emptyList: false,
			selectedCompany: props.selectedCompany,
			generatePdf: props.generatePdf,
			timer: 0,
		};
	}

	componentWillReceiveProps(nextProps) {
		if (
			nextProps.dataNotes !== this.state.dataNotes ||
			nextProps.filter !== this.state.filter ||
			nextProps.paymentTypes !== this.state.paymentTypes ||
			nextProps.emptyList !== this.state.emptyList ||
			nextProps.selectedCompany !== this.state.selectedCompany ||
			nextProps.generatePdf !== this.state.generatePdf
		) {
			this.setState({
				dataNotes: nextProps.dataNotes,
				filter: nextProps.filter,
				paymentTypes: nextProps.paymentTypes,
				emptyList: nextProps.emptyList,
				selectedCompany: nextProps.selectedCompany,
				generatePdf: nextProps.generatePdf,
			});
			this.noteIsEmpty(nextProps.dataNotes);
		}

		if (nextProps.filter !== this.state.filter) {
			this.setState({
				checkboxAll: false,
			});
		}
		return null;
	}

	componentWillMount() {
		this.noteIsEmpty(this.state.dataNotes);
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.checkboxAll !== this.state.checkboxAll) {
			setTimeout(() => {
				const secondes = moment().format('ss');
				if (this.state.timer + 2 <= secondes) {
					this.handleChecked(this.state.checkboxAll ? 'all' : '');
					if (this.state.filter !== 'credits') {
						this.props.onUpdateGenerationPdf(true);
						this.setState({
							generatePdf: true,
						});
					}
				}
			}, WAIT_INTERVAL);
		}

		if (prevState.paymentIds !== this.state.paymentIds) {
			setTimeout(() => {
				const secondes = moment().format('ss');
				if (this.state.timer + 2 <= secondes) {
					this.props.onUpdatePaymentIds(this.state.paymentIds);
					if (this.state.filter !== 'credits' && this.state.paymentIds.length) {
						this.props.onUpdateGenerationPdf(true);
						this.setState({
							generatePdf: true,
						});
					}
				}
			}, WAIT_INTERVAL);
		}
		if (prevState.creditDetail !== this.state.creditDetail) {
			this.props.onUpdateCreditDetails(this.state.creditDetail);
		}
	}

	noteIsEmpty = PropsdataNotes => {
		let list = [];
		const dataNotes = PropsdataNotes ? PropsdataNotes : this.state.dataNotes;
		Object.keys(dataNotes).map((customerId, i) => {
			return dataNotes[customerId] &&
				dataNotes[customerId].details &&
				dataNotes[customerId].details[customerId]
				? list.push(dataNotes[customerId].details[customerId])
				: [];
		});

		if (
			(list.length === 1 && list[0] && list[0].length === 0) ||
			(list.length === 2 &&
				list[0] &&
				list[0].length === 0 &&
				list[1] &&
				list[1].length === 0)
		) {
			this.props.onUpdateEmptyListBool(true);
		} else {
			this.setState({
				emptyList: false,
			});
		}
	};

	handleAllChecked = () => {
		const checkboxes = window.document.querySelectorAll("input[name='ticket']");
		const { checkboxAll } = this.state;
		for (let i = 0; i < checkboxes.length; i++) {
			checkboxes[i].checked = !checkboxAll;
		}
		const secondes = moment().format('ss');
		this.setState({
			checkboxAll: !checkboxAll,
			emptyList: false,
			timer: parseInt(secondes, 10),
		});
	};

	handleChecked = isAllChecked => {
		// find all checkbox checked
		const checkboxes = window.document.querySelectorAll(
			"input[type='checkbox']"
		);
		const checkboxChecked = [];
		for (var i = 0; i < checkboxes.length; i++) {
			if (checkboxes[i].checked) {
				if (
					checkboxes[i] &&
					checkboxes[i].attributes &&
					checkboxes[i].attributes.customerid &&
					checkboxes[i].attributes.customerid.value
				) {
					checkboxChecked.push({
						idPayment: checkboxes[i].value,
						customerId: checkboxes[i].attributes.customerid.value,
						userDb:
							checkboxes[i] &&
							checkboxes[i].attributes &&
							checkboxes[i].attributes.userdb &&
							checkboxes[i].attributes.userdb.value
								? checkboxes[i].attributes.userdb.value
								: '',
					});
				}
			}
		}
		const radioButton = window.document.querySelector(
			'input[name="ticket"]:checked'
		);
		const secondes = moment().format('ss');

		this.setState(
			{
				checkboxAll: isAllChecked === 'all' ? true : false,
				paymentIds: checkboxChecked,
				creditDetailTs:
					radioButton && radioButton.value ? radioButton.value : '',
				timer: parseInt(secondes, 10),
			},
			async () => {
				const { dataNotes, creditDetailTs } = this.state;
				if (creditDetailTs) {
					let details;
					for (const notes in dataNotes) {
						const list = dataNotes[notes].details[notes];
						details = await list.find(note => {
							return note.timestamp == creditDetailTs;
						});
					}
					this.setState({ creditDetail: details });
				}
			}
		);
	};
	
	handleInputChange = (e, note) => {
		const { filter } = this.state;
	  
		if (filter === 'credits') {
			this.setState({
				selectedRadio: e.target.value,
			});
		} else {
			const { selectedCheckboxes } = this.state;
			const newCheckboxes = { ...selectedCheckboxes };
			newCheckboxes[note.timestamp] = e.target.checked;
		
			this.setState({
				selectedCheckboxes: newCheckboxes,
			});
		}
	  };

	render() {
		const { filter, paymentTypes, language, generatePdf } = this.state;

		/**
		 * render bloc list of tickets
		 */
		const getNotesList = () => {
			const { ...dataNotes } = this.state.dataNotes;
			let list;
			let userDb;
			if (dataNotes) {
				return Object.keys(dataNotes).map((customerId, i) => {
					list =
						dataNotes[customerId] &&
						dataNotes[customerId].details &&
						dataNotes[customerId].details[customerId]
							? dataNotes[customerId].details[customerId]
							: [];

					if (list && list.length > 0) {
						return list.map(note => {
							const noteTs = {
								date: moment.unix(note.timestamp).format('DD/MM/YYYY'),
								time: moment.unix(note.timestamp).format('HH:mm'),
							};
							const paidTs = {
								date: moment.unix(note.paid_timestamp).format('DD/MM/YYYY'),
								time: moment.unix(note.paid_timestamp).format('HH:mm'),
							};

							const isSelected = this.state.paymentIds.some(
								p => p.idPayment === note.payment_line_id
							);
							//check du rechargement du module / remplace le map par un reduce.
							return (
								<tr
									className={`flex-table ${generatePdf ? 'disabled-note' : ''}`}
									role="rowgroup"
									//onClick={this.handleChecked}
									onChange={this.handleChecked}
									name="ticket"
									value={note.payment_line_id || note.timestamp}
									key={`tr-${note.timestamp}-${Math.floor(
										Math.random() * 100000
									)}`}>
									<td
										className="flex-row check"
										role="cell"
										key={`td-${note.timestamp}-${i}`}>
										<input
											type={
												this.state.filter === 'credits' ? 'radio' : 'checkbox'
											}
											id={note.timestamp}
											onChange={e => this.handleInputChange(e, note)}
											name="ticket"
											customerid={customerId}
											userdb={note.userDb}
											checked={
												this.state.filter === 'credits'
												  ? this.state.selectedRadio === (note.payment_line_id || note.timestamp)
												  : this.state.selectedCheckboxes?.[note.timestamp] || this.state.checkboxAll || isSelected
											  }
											value={note.payment_line_id || note.timestamp}
										/>
									</td>
									<td className="flex-row tickets" role="cell">
										<span className="ticket-date">
											{filter === 'credits'
												? I18n.t('customerAccount.oneCredits')
												: 'Ticket'}{' '}
											{I18n.t('customerAccount.of')} {noteTs.date} à{' '}
											{noteTs.time}
										</span>
										<span className="ticket-amount">
											{note && note.amount
												? priceFormatting(note.amount, language, 'EUR')
												: priceFormatting(0, language, 'EUR')}{' '}
										</span>
										{filter === 'paid' ? (
											<span className="ticket-paid">
												{I18n.t('customerAccount.paidAt')} {paidTs.date}{' '}
												{I18n.t('customerAccount.a')} {paidTs.time}
											</span>
										) : (
											<span className="ticket-paid">
												{paymentTypes &&
												paymentTypes.length > 1 &&
												note.id_payment_type
													? getPaymentName(paymentTypes, note.id_payment_type)
													: ''}
											</span>
										)}
										{filter === 'credits' && note && note.infos && note ? (
											<span className="ticket-paid">
												{note.infos[0] && note.infos[0].user_lastname
													? note.infos[0].user_lastname
													: ''}{' '}
												{note.infos[0] && note.infos[0].user_firstname
													? note.infos[0].user_firstname
													: ''}
											</span>
										) : (
											''
										)}
									</td>
								</tr>
							);
						});
					}
				});
			}
		};

		const renderSelectAll = () => {
			if (this.state.emptyList) {
				return (
					<tr className="ticket-empty">
						<th className="empty">
							{filter === 'credits'
								? I18n.t('customerAccount.noCreditFound')
								: I18n.t('customerAccount.noTicketfound')}
						</th>
					</tr>
				);
			} else {
				return (
					<React.Fragment>
						<tr
							className="flex-table label-title"
							role="rowgroup"
							name="ticket"
							key={`tr-head`}>
							<td className="flex-row check" role="cell" key={`td-head`} />
							<td className="flex-row tickets" role="cell">
								<span className="ticket-date">
									{I18n.t('customerAccount.ticketDate')}
								</span>
								<span className="ticket-amount">
									{filter === 'credits'
										? I18n.t('customerAccount.amountCredit')
										: I18n.t('customerAccount.amountIvat')}
								</span>
								{filter === 'paid' ? (
									<span className="ticket-paid">
										{I18n.t('customerAccount.paymentDate')}
									</span>
								) : (
									''
								)}
								{filter === 'credits' ? (
									<span className="ticket-paid">
										{I18n.t('customerAccount.user')}
									</span>
								) : (
									''
								)}
							</td>
						</tr>
						{filter !== 'credits' ? (
							<tr
								className={`check-all ${generatePdf ? 'disabled-note' : ''}`}
								key={`all-${this.state.filter}`}
								onClick={e => this.handleAllChecked(e)}>
								<th
									className="flex-row check"
									role="columnheader"
									key={`all-${this.state.filter}`}>
									<input
										type="checkbox"
										id="all"
										value=""
										checked={this.state.checkboxAll}
										onChange={e => {
											console.log('Input changed');
										}}
									/>
								</th>
								<th className="flex-row tickets line" role="columnheader">
									{I18n.t('customerAccount.selectAll')}
								</th>
							</tr>
						) : (
							''
						)}
					</React.Fragment>
				);
			}
		};

		return (
			<table className="main" role="table">
				<thead className="header" key={`all-${this.state.filter}`}>
					<tr
						className="flex-table header"
						role="rowgroup"
						key={`all-${this.state.filter}`}
					/>
				</thead>
				<tbody>
					{renderSelectAll()}
					{getNotesList()}
				</tbody>
			</table>
		);
	}
	static defaultProps = {
		filter: 'unpaid',
		dataNotes: {},
		checkboxAll: false,
		paymentTypes: {},
		companies: {},
		language: window.navigator.language,
		selectedCompany: '',
		generatePdf: false,
	};
}

CustomerNoteList.propTypes = {
	filter: PropTypes.string.isRequired,
	dataNotes: PropTypes.object,
	checkboxAll: PropTypes.bool.isRequired,
	onUpdateLinkPdf: PropTypes.func.isRequired,
	onUpdatePaymentIds: PropTypes.func.isRequired,
	onUpdateCreditDetails: PropTypes.func.isRequired,
	paymentTypes: PropTypes.object,
	language: PropTypes.array.isRequired,
	onUpdateEmptyListBool: PropTypes.func.isRequired,
	selectedCompany: PropTypes.string,
	generatePdf: PropTypes.bool.isRequired,
	onUpdateGenerationPdf: PropTypes.func.isRequired,
};

export default CustomerNoteList;
