import React from "react";
import ReactDOM from "react-dom";
import { browserHistory } from "react-router";
import { Provider } from "react-redux";
import { createStore, applyMiddleware, compose } from "redux";
import { apiMiddleware } from "redux-api-middleware";
import thunk from "redux-thunk";
import rootReducer from "./rootReducer";
import setAuthorizationToken from "./utils/setAuthorizationToken";
// import {generateAccessToken} from './common/login/actions/authActions';
import getUrlParams from "./utils/getUrlParams";
// import {addFlashMessage} from './common/flashMessage/actions/flashMessages';
import config from "./utils/config";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min";
import ConnectedAs from "./components/ConnectedAs";
import ReactGA from "react-ga";
import "babel-polyfill";
import Root from "./Root";
import langs from "./lang";
import {
  loadTranslations,
  setLocale,
  syncTranslationWithStore,
} from "react-redux-i18n";
import decode from "jwt-decode";
import axios from "axios";
//import { setUserLocal } from "../actions/localAction";
if (config.useReactGa) {
  ReactGA.initialize("UA-39334655-1");
}

function logPageView() {
  if (config.useReactGa) {
    ReactGA.set({ page: window.location.pathname + window.location.search });
    ReactGA.pageview(window.location.pathname + window.location.search);
  }
}


const composeEnhancers =
window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;


const clear = getUrlParams("clear");
const connectAs = getUrlParams("connect-as");
const store = createStore(
  rootReducer,
  compose(
    composeEnhancers(applyMiddleware(setAuthorizationToken(), apiMiddleware, thunk)),

    //window.devToolsExtension ? window.devToolsExtension() : (f) => f
  )
);

syncTranslationWithStore(store);
const langsArr = Object.keys(langs);
let lang = window.navigator.language || "fr";
if (langsArr.indexOf(lang) === -1) {
  lang = "fr";
}
store.dispatch(loadTranslations(langs));
store.dispatch(setLocale(lang));

if (connectAs) {
  ReactDOM.render(
    <Provider store={store}>
      <ConnectedAs viewAsCustomerToken={connectAs} />
    </Provider>,
    document.getElementById("app")
  );
} else {
  if (clear) {
    if (window.localStorage.cacheCleared) {
      window.localStorage.clear();
      browserHistory.push("/");
    } else {
      window.localStorage.clear();
      window.localStorage.cacheCleared = true;
      document.location.reload(true);
    }
  }

  if (!window.localStorage.refreshToken && window.localStorage.accessToken) {
    window.localStorage.removeItem("accessToken");
  }

  const refreshToken = getUrlParams("refreshToken");

  const viewAsCustomerToken = getUrlParams("viewAsCustomerToken");
  if (refreshToken !== undefined) {
    window.localStorage.setItem("refreshToken", refreshToken);
  }
  if (viewAsCustomerToken !== undefined) {
    window.localStorage.setItem("viewAsCustomerToken", viewAsCustomerToken);
  }
  window.setInterval(() => {
    let url;
    const { refreshToken, fiscAccessToken, accessToken } = window.localStorage;
    let decoded;
    try {
      decoded = decode(accessToken);
      const exp = decoded.exp;
      const now = Date.now() / 1000;
      if (exp - now <= 180) {
        const type =
          fiscAccessToken !== undefined ? "fiscAccessToken" : "refreshToken";
        switch (type) {
          case "refreshToken":
            url =
              config.reportingUrl +
              "api/auth/accessToken/?refreshToken=" +
              refreshToken;
            break;
          case "fiscAccessToken":
            url =
              config.reportingUrl +
              "api/auth/accessToken/administration-fiscale/?refreshToken=" +
              refreshToken;
            break;
          default:
            url =
              config.reportingUrl +
              "api/auth/accessToken/?refreshToken=" +
              refreshToken;
            break;
        }
        axios
          .get(url)
          .then((res) => {
            console.log("token renewed");
            window.localStorage.accessToken = res.data.accessToken;
            //document.location.reload(true);
            //return res.data.accessToken;
          })
          .catch((err) => {
            console.log(err);
            setAuthorizationToken(false);
            window.localStorage.removeItem("accessToken");
            window.localStorage.removeItem("refreshToken");
            document.location.reload(true);
            // window.location.replace(config.authServerUrl);
          });
      }
    } catch (err) {
      console.log("err", err);
    }
  }, 180000);
  render();
}

function render() {
  ReactDOM.render(
    <Root store={store} logPageView={logPageView} />,
    document.getElementById("app")
  );
}
