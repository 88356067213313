import React, { useState, useEffect } from 'react';
import GlobalSettings from '../components/GlobalSettings';

export default function AdminAccounts({ vatKeys, onSave }) {
	return (
		<div className="vat-admin-main">
			<GlobalSettings vatKeys={vatKeys} onSave={onSave} />
		</div>
	);
}
