import React from 'react';
import config from '../../../utils/config';

function ControlStrip() {
	return (
		<div id="main-container" className="main-container nav-effect-1">
			<iframe
				style={{ top: '0px' }}
				id="ouilink-iframe"
				src={`${config.reportingV4Url}shift-details`}
			/>
		</div>
	);
}
export default ControlStrip;
