export const vatExportLabels = {
  orderLines: [
    {
      Header: `JournalCode`,
      accessor: "journal",
      headerClassName: "main_header_title",
    },
    {
      Header: `JournalLib`,
      accessor: "journallib",
      headerClassName: "main_header_title",
    },
    {
      Header: `EcritureNum`,
      accessor: "ecritureNum",
      headerClassName: "main_header_title",
    },
    {
      Header: `EcritureDate`,
      accessor: "date",
      headerClassName: "main_header_title",
    },
    {
      Header: `CompteNum`,
      accessor: "account",
      headerClassName: "main_header_title",
    },
    {
      Header: `CompteLib`,
      accessor: "lib",
      headerClassName: "main_header_title",
    },
    {
      Header: `CompAuxNum`,
      accessor: "accountAux",
      headerClassName: "main_header_title",
    },
    {
      Header: `CompAuxLib`,
      accessor: "accountAuxLib",
      headerClassName: "main_header_title",
    },
    {
      Header: `PieceRef`,
      accessor: "numberCaisse",
      headerClassName: "main_header_title",
    },
    {
      Header: `PieceDate`,
      accessor: "numberCaisseDate",
      headerClassName: "main_header_title",
    },
    {
      Header: `EcritureLib`,
      accessor: "ecritureLib",
      headerClassName: "main_header_title",
    },
    {
      Header: `Debit`,
      accessor: "debit",
      headerClassName: "main_header_title",
    },
    {
      Header: `Credit`,
      accessor: "credit",
      headerClassName: "main_header_title",
    },
    {
      Header: `EcritureLet`,
      accessor: "ecritureLet",
      headerClassName: "main_header_title",
    },
    {
      Header: `DateLet`,
      accessor: "dateLet",
      headerClassName: "main_header_title",
    },
    {
      Header: `ValidDate`,
      accessor: "validDate",
      headerClassName: "main_header_title",
    },
    {
      Header: `Montantdevise`,
      accessor: "montantdevise",
      headerClassName: "main_header_title",
    },
    {
      Header: `idevise`,
      accessor: "idevise",
      headerClassName: "main_header_title",
    },
    /*{
      Header: `Société`,
      accessor: "society",
      headerClassName: "main_header_title",
      className: "table_string_field",
    },*/
    /*{
      Header: `Etab`,
      accessor: "etab",
      headerClassName: "main_header_title",
    },*/
    /*{
      Header: `Type de pièce`,
      accessor: "pieceType",
      headerClassName: "main_header_title",
    },*/
    /*{
      Header: `Axe analytique`,
      accessor: "axeAnal",
      headerClassName: "main_header_title",
    },*/
    /*{
      Header: `Section analytique`,
      accessor: "sectionAnal",
      headerClassName: "main_header_title",
    },*/
    /*{
      Header: `Type`,
      accessor: "type",
      headerClassName: "main_header_title",
    },*/
    /*{
      Header: `Echéance`,
      accessor: "echeance",
      headerClassName: "main_header_title",
    },
    {
      Header: `Sens`,
      accessor: "sens",
      headerClassName: "main_header_title",
    },
    {
      Header: `Montant`,
      accessor: "amount",
      headerClassName: "main_header_title",
    },
    {
      Header: `Devise`,
      accessor: "devise",
      headerClassName: "main_header_title",
    },*/
    /*{
      Header: `Montant en Devise`,
      accessor: "amountD",
      headerClassName: "main_header_title",
    },*/
    /*{
      Header: `Mode Règlement`,
      accessor: "reglMode",
      headerClassName: "main_header_title",
    },
    {
      Header: `Tiers`,
      accessor: "tiers",
      headerClassName: "main_header_title",
    },
    {
      Header: `Profil TVA`,
      accessor: "profilVat",
      headerClassName: "main_header_title",
    },
    {
      Header: `Intraco`,
      accessor: "intraco",
      headerClassName: "main_header_title",
    },
    {
      Header: `Nature`,
      accessor: "nature",
      headerClassName: "main_header_title",
    },
    {
      Header: `Section Axe 1`,
      accessor: "sectionAxe1",
      headerClassName: "main_header_title",
    },
    {
      Header: `Section Axe 2`,
      accessor: "sectionAxe2",
      headerClassName: "main_header_title",
    },
    {
      Header: `Section Axe 3`,
      accessor: "sectionAxe3",
      headerClassName: "main_header_title",
    },*/
  ],
};
